import { Accordion, AccordionPanel } from '@stageplus/components/src/accordion';
import MinusIcon from '@stageplus/icons/react/minus';
import PlusIcon from '@stageplus/icons/react/plus';
import { FAQPageJsonLd } from 'next-seo';
import { Faq } from 'src/utilities/get-faqs';

type FaqsProps = {
  faqs: Faq[];
};

/**
 * Renders a list of FAQ items. The list is divided into two columns on larger
 * screens. When an item is clicked, it will expand or collapse. Only one item
 * can be expanded at a time. Structured data is automatically generated for the
 * FAQ items and inserted into the `<head>` tag of the page for SEO purposes.
 */
export function FaqList({ faqs }: FaqsProps) {
  /**
   * Divide the FAQs into two arrays of equal length.
   * Using a standard grid layout would cause FAQ items in the other column to
   * be pushed down when an item is expanded.
   */
  const leftColumnItems = faqs.slice(0, Math.ceil(faqs.length / 2));
  const rightColumnItems = faqs.slice(Math.ceil(faqs.length / 2));

  if (faqs.length === 0) {
    return null;
  }

  return (
    <>
      {/* Structured data for the FAQ items */}
      <FAQPageJsonLd
        mainEntity={faqs.map(({ question, answer }) => ({
          questionName: question,
          acceptedAnswerText: answer,
        }))}
      />
      {/* FAQ items */}
      <Accordion className="md:grid md:grid-cols-2 md:gap-x-6 xl:gap-x-8" data-test="faq-list">
        {[leftColumnItems, rightColumnItems].map((faqs, index) => (
          <div key={index} className="divide-y divide-divider border-y border-divider">
            {faqs.map((faq, index) => (
              <AccordionPanel
                id={`faq-${faq.id}`}
                key={index}
                label={({ open }) => (
                  <span className="flex w-full justify-between py-2">
                    <span>{faq.question}</span>
                    {open ? <MinusIcon /> : <PlusIcon />}
                  </span>
                )}
                className="py-2"
                data-test="faq-item"
              >
                <div
                  className="mb-2 mt-4"
                  data-test="faq-item-answer"
                  dangerouslySetInnerHTML={{
                    __html: faq.answer,
                  }}
                />
              </AccordionPanel>
            ))}
          </div>
        ))}
      </Accordion>
    </>
  );
}
