import { useCallback, useState } from 'react';
import useInterval from 'src/hooks/use-interval';
import { differenceInSeconds } from 'src/utilities/date-helpers';

/**
 * Returns the number of seconds between a given date and now. Will update every
 * second. The number returned can be negative.
 */
export default function useCountdown(date: Date | string): number {
  const [value, setValue] = useState<number>(differenceInSeconds(new Date(date)));

  const updateUpcomingLiveConcert = useCallback(() => {
    setValue(differenceInSeconds(new Date(date)));
  }, [date]);

  // Update the upcoming live concert every second
  useInterval(updateUpcomingLiveConcert, 1000);

  return value;
}
